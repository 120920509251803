import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from 'components/Footer'
import { StaticQuery, graphql } from 'gatsby'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Helmet
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate='Hint.io - %s'
          meta={[
            {
              name: 'description',
              content:
                'Hint is an agency with a passion for solving complex business problems and a knack for fixing broken software. How can we help you?'
            },
            {
              name: 'keywords',
              content: 'software, consulting, ruby, rails, engineering'
            },
            { property: 'og:title', content: data.site.siteMetadata.title },
            {
              property: 'og:description',
              content:
                'Hint is an agency with a passion for solving complex business problems and a knack for fixing broken software. How can we help you?'
            },
            { property: 'og:site_name', content: 'Hint.io' },
            { property: 'twitter:site', content: '@hintmedia' }
          ]}
        />
        <div style={{ flex: '1 0 auto' }}>
          <div>{children}</div>
        </div>
        <Footer />
      </div>
    )}
  />
)

export default Layout
