import { COLORS, SPACING, FONT_SIZES } from 'helpers/globals'
import { hideAtLg, styleUpToLg, styleFromLg } from 'helpers/screens'

export default {
  container: {
    backgroundColor: COLORS.darkGrey,
    color: COLORS.white,
    paddingTop: SPACING.xxl,
    ...styleUpToLg({
      textAlign: 'center',
      paddingTop: SPACING.large
    }),
    flexShrink: 0
  },
  innerContainer: {
    paddingBottom: SPACING.xl,
    ...styleUpToLg({
      paddingBottom: 0
    })
  },
  colHeaderContainer: {
    lineHeight: SPACING.base,
    borderBottom: `1px solid ${COLORS.grey}`,
    paddingBottom: SPACING.small,
    marginBottom: SPACING.small,
    letterSpacing: SPACING.letters.small,
    '& h5': {
      margin: 0,
      display: 'inline'
    },
    '& > a > img, & > img': {
      height: SPACING.base,
      verticalAlign: 'text-bottom'
    },
    ...styleUpToLg({
      borderBottom: 'none',
      marginBottom: 0
    })
  },
  contactCol: {
    ...styleUpToLg({
      paddingTop: SPACING.large,
      paddingBottom: SPACING.small
    })
  },
  softwareCol: {
    ...styleUpToLg({
      paddingTop: SPACING.large
    })
  },
  copyright_container: {
    backgroundColor: COLORS.black,
    color: COLORS.white,
    padding: `${SPACING.small} ${SPACING.xxxl} ${SPACING.small} ${SPACING.xxxl}`,
    ...hideAtLg
  },
  email: {
    fontSize: FONT_SIZES.micro,
    fontWeight: 'bold',
    letterSpacing: SPACING.letters.small
  },
  serviceList: {
    ...hideAtLg,
    listStyleType: 'none',
    paddingLeft: 0,
    '& > li': {
      paddingBottom: SPACING.tiny
    }
  },
  mobileHR: {
    width: '85%',
    maxWidth: '400px',
    margin: '0 auto',
    ...styleFromLg({
      display: 'none'
    })
  },
  desktopIconContainer: {
    ...hideAtLg
  },
  mobileIconContainer: {
    margin: '0 auto',
    marginBottom: SPACING.large,
    ...styleFromLg({
      display: 'none'
    })
  },
  socialIcon: {
    fontSize: SPACING.large,
    marginRight: SPACING.small
  }
}
