import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import Link from 'gatsby-link'

const ImageLink = ({ src, imageClass, alt, href, classes }) => (
  <Link className={classes.link} to={href}>
    <img src={src} alt={alt} className={imageClass} />
  </Link>
)

export default injectSheet(style)(ImageLink)
