import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from 'assets/images/logo.svg'
import ImageLink from 'components/ImageLink'
import Link from 'gatsby-link'

const socialIcons = (containerClass, iconClass) => (
  <div className={containerClass}>
    <a href='https://github.com/hintmedia' target='_blank'>
      <FontAwesomeIcon className={iconClass} icon={['fab', 'github']} />
    </a>
    <a href='https://twitter.com/hintmedia' target='_blank'>
      <FontAwesomeIcon className={iconClass} icon={['fab', 'twitter']} />
    </a>
    <a href='https://keybase.io/team/hintio' target='_blank'>
      <FontAwesomeIcon className={iconClass} icon={['fab', 'keybase']} />
    </a>
    <a href='https://hint.io/blog/rss.xml' target='_blank'>
      <FontAwesomeIcon className={iconClass} icon='rss' />
    </a>
  </div>
)

const Footer = ({ classes }) => (
  <div className={classes.container}>
    <div className={`container ${classes.innerContainer}`}>
      {socialIcons(classes.mobileIconContainer, classes.socialIcon)}
      <div className='row'>
        <hr className={classes.mobileHR} />
        <div className={`col-lg ${classes.contactCol}`}>
          <div className={classes.colHeaderContainer}>
            <ImageLink src={logo} alt='Hint Logo' href='/' />
          </div>
          <p className={classes.email}>
            <a href='mailto:contact@hint.io'>CONTACT@HINT.IO</a>
          </p>
          {socialIcons(classes.desktopIconContainer, classes.socialIcon)}
        </div>
        <hr className={classes.mobileHR} />
        <div className={`col-lg ${classes.softwareCol}`}>
          <div className={classes.colHeaderContainer}>
            <h5>
              <Link to='/services#platformOperations'>PLATFORM / OPERATIONS</Link>
            </h5>
          </div>
          <ul className={classes.serviceList}>
            <li>
              <Link to='/services#improvedAutomation'>Improved CI/CD Automation</Link>
            </li>
            <li>
              <Link to='/services#developerTooling'>Custom Developer Tooling</Link>
            </li>
            <li>
              <Link to='/services#kubernetes'>Deployment on Kubernetes</Link>
            </li>
          </ul>
        </div>
        <div className='col-lg'>
          <div className={classes.colHeaderContainer}>
            <h5>
              <Link to='/services#softwareDevelopment'>SOFTWARE DEVELOPMENT</Link>
            </h5>
          </div>
          <ul className={classes.serviceList}>
            <li>
              <Link to='/services#ruby'>Ruby / Rails</Link>
            </li>
            <li>
              <Link to='/services#javascript'>Javascript / React</Link>
            </li>
            <li>
              <Link to='/services#elixir'>Elixir / Phoenix</Link>
            </li>
          </ul>
        </div>
        <div className='col-lg'>
          <div className={classes.colHeaderContainer}>
            <h5>
              <Link to='/services#processTraining'>PROCESS & TRAINING</Link>
            </h5>
          </div>
          <ul className={classes.serviceList}>
            <li>
              <Link to='/services#processTraining'>Practices & Methodology</Link>
            </li>
            <li>
              <Link to='/services#processTraining'>Mentoring & Pair Programming</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={classes.copyright_container}>
      <div className='container'>&copy; {new Date().getFullYear()} Hint Media Inc.</div>
    </div>
  </div>
)

export default injectSheet(style)(Footer)
